/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Spin, Tabs, Row, Col, Button, Alert } from 'antd';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useAuth } from 'hooks/auth';
import { useExecution } from 'hooks/execution';
import { useFetch } from 'services/hooks/index';

import ExecutionSubheader from 'components/Header/components/ExecutionSubheader';
import ZoomedImage from 'components/Image/ZoomedImage';

import Wrapper from 'pages/private/wrapper';

import { Edit, Plus1 } from '@combateafraude/icons/general';
import DynamicCardsList from './components/DynamicCardsList';
import ComplianceRules from './components/ComplianceRules';
import DataConfirmation from './components/DataConfirmation';
import LicenseConfirmation from './components/LicenseConfirmation';
import DocumentsConfirmation from './components/DocumentsConfirmation';
import InformationBanner from './components/InformationBanner';
import OfficialDataReprove from './components/double-checks/OfficialDataReprove';
import OCRDetails from './components/OCRDetails';
import Parameters from './components/Parameters';
import ProcessedInfos from './components/ProcessedInfos';
import SentImages from './components/SentImages';
import CustomDataAvailable from './components/double-checks/CustomDataAvailable';
import Liveness from './components/double-checks/Liveness';
import LivenessPending from './components/double-checks/LivenessPending';
import PendingFacematch from './components/double-checks/PendingFacematch';
import SanctionsReprove from './components/double-checks/SanctionsReprove';
import FacesetConfirmation from './components/FacesetConfirmation';
import ProcessesInformation from './components/ProcessesInformation';
import RGIssueLessThan25 from './components/double-checks/RGIssueLessThan25';
import Timeline from './components/Timeline';
import SentImageFaceMatch from '../ExecutionDetailsDoubleCheckFacematch/components/SentImages';
import WorkflowDetails from './components/WorkflowDetails';

import './styles.less';
import ServicesDetails from './components/ServicesDetails';
import DeepfakeConfirmation from './components/DeepfakeConfirmation';

const { TabPane } = Tabs;

const Details = () => {
  const [operators, setOperators] = useState([]);
  const operatorsRef = useRef([]);

  useEffect(() => {
    operatorsRef.current = operators;
  }, [operators]);

  const { tenantId, reportId, executionId } = useParams();
  const { executionData, loadingExecution, loadData, error } = useExecution();
  const { post, loading } = useFetch();
  const { user } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = useCallback(() => {
    if (!isDrawerOpen) {
      setIsDrawerOpen(true);
    }
  }, [isDrawerOpen]);

  const closeDrawer = useCallback(() => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [isDrawerOpen]);

  const alertErrorExecutionLoad = useMemo(() => {
    return (
      <div id="alert-error-execution-load">
        <Alert
          message="Erro"
          showIcon
          description={`O seguinte erro ocorreu com essa execução: ${error?.message}`}
          type="error"
          action={
            <Button
              size="small"
              danger
              onClick={() => {
                window.location.reload();
              }}
            >
              Recarregar a página
            </Button>
          }
        />
      </div>
    );
  }, [error]);

  const imageTabs = useCallback(
    (height = undefined) => {
      if (!executionData) return <></>;

      const { images } = executionData.executions;

      return (
        images && (
          <Tabs className="tabs-zoomed-images">
            <TabPane key="1" tab="Frente Recortada">
              <ZoomedImage
                src={images.selectedFront}
                alt="Imagem da frente recortada do documento"
                height={height}
              />
            </TabPane>
            <TabPane key="2" tab="Verso Recortado">
              <ZoomedImage
                src={images.selectedBack}
                alt="Imagem do verso recortado do documento"
                height={height}
              />
            </TabPane>
            <TabPane key="3" tab="Selfie">
              <ZoomedImage src={images.selfie} alt="Imagem de selfie" height={height} />
            </TabPane>
            <TabPane key="4" tab="Frente">
              <ZoomedImage
                src={images.front}
                alt="Imagem da frente do documento"
                height={height}
              />
            </TabPane>
            <TabPane key="5" tab="Verso">
              <ZoomedImage
                src={images.back}
                alt="Imagem do verso do documento"
                height={height}
              />
            </TabPane>
            {executionData?.executions?.images?.brbLicense && (
              <TabPane key="6" tab="Alvará">
                <ZoomedImage src={images.back} alt="Imagem do alvará" height={height} />
              </TabPane>
            )}
          </Tabs>
        )
      );
    },
    [executionData]
  );

  useEffect(() => {
    loadData({ tenantId, reportId, executionId });
  }, [loadData, tenantId, reportId, executionId]);

  const pendingFacematch = useMemo(() => {
    if (!executionData) return <></>;

    const { validations } = executionData?.executions || [];
    const { sections } = executionData?.executions || {};

    const hasFacematchRule = validations.find((v) => v.rule === 'facematch_is_equal');
    if (hasFacematchRule?.status === 'PENDING' && !sections.facematch) {
      return <PendingFacematch />;
    }

    return <></>;
  }, [executionData]);

  const pendingProcess = useMemo(() => {
    if (!executionData) return false;

    const { validations } = executionData?.executions || [];
    const hasNoProcessesRule = validations.find((v) => v.rule === 'has_no_processes');

    return !!(
      executionData?.executions?.sections?.pfProcessesAlt?.data?.results &&
      hasNoProcessesRule?.status === 'INVALID'
    );
  }, [executionData]);

  const showOCRConfirm = useMemo(() => {
    const validType = ['RG', 'CNH', 'RNE', 'CRLV', 'OUTROS'].includes(
      executionData?.executions?.type?.toUpperCase()
    );
    const isPendingOcr = executionData?.executions?.status === 'PENDENTE OCR';
    const isValidType = validType || executionData?.executions?.type === null;

    return isPendingOcr && isValidType;
  }, [executionData]);

  const showOCRDetails = useMemo(() => {
    const validType = executionData?.executions?.type?.toUpperCase() || false;
    const hasOcr = executionData?.executions?.sections?.ocr;
    const isValidType = validType || executionData?.executions?.type === null;

    return hasOcr && isValidType;
  }, [executionData]);

  const showSharedFacesetReview = useMemo(() => {
    const faceMatches =
      executionData?.executions?.sections?.sharedFaceset?.data?.faceMatches || [];

    return !!faceMatches.length;
  }, [executionData]);

  const showPrivateFacesetReview = useMemo(() => {
    const privateFacesetReview = executionData?.executions?.reviews?.PRIVATE_FACESET;

    const faceMatches =
      executionData?.executions?.sections?.privateFaceset?.data?.faceMatches || [];

    return privateFacesetReview && privateFacesetReview.reviewAt && !!faceMatches.length;
  }, [executionData]);

  useEffect(() => {
    try {
      const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);

      socket.addEventListener('open', () => {
        socket.send(
          JSON.stringify({
            action: 'subscribeAndPublish',
            topic: `backoffice-execution-${executionId}`,
            infoData: { executionId, name: user.name, userId: user.username },
          })
        );
      });

      socket.addEventListener('message', (event) => {
        const message = JSON.parse(event.data);

        let newOperators = [];
        if (message.type === 'disconnect') {
          const disconnectedClient = message.content;

          newOperators = operatorsRef.current.filter(
            (operator) => operator.socketId !== disconnectedClient.id
          );

          setOperators(() => [...newOperators]);
        } else if (message.type !== 'error') {
          const messageContentByExecutionId = message.content.filter(
            (socketClient) =>
              socketClient.infoData?.executionId === executionId &&
              socketClient.topics.find((t) => t === `backoffice-execution-${executionId}`)
          );

          newOperators = messageContentByExecutionId
            .map((socketClient) => ({
              socketId: socketClient.id,
              executionId: socketClient.infoData.executionId,
              name: socketClient.infoData.name,
              userId: socketClient.infoData.userId,
              createdAt: socketClient.createdAt,
            }))
            .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

          setOperators(() => [...newOperators]);
        }
      });

      return () => {
        socket.close();
      };
    } catch {
      // SILENCER
      return () => {};
    }
  }, []); // eslint-disable-line

  const bannerData = useMemo(() => {
    if (!operators || operators.length === 0) return { hideBanner: true };

    const mainOperator = operators[0];

    if (mainOperator.userId === user.username) return { hideBanner: true };
    return {
      operator: mainOperator,
      hideBanner: false,
    };
  }, [operators]); // eslint-disable-line

  const formattedErrorStatusCode = error?.statusCode.toString();

  const hasExecutionError = ['500', '503', '401', '400'].includes(
    formattedErrorStatusCode
  );

  const containsImages = executionData?.executions.images
    ? Object.keys(executionData?.executions.images).length > 0
    : '';

  return (
    <>
      <InformationBanner
        icon={<Edit width={16} height={16} />}
        bannerData={bannerData}
        bannerType="socket-operator"
      />

      <Wrapper
        id="execution-wrapper-component"
        subheader={<ExecutionSubheader post={post} />}
      >
        {(loadingExecution && !executionData) || loading ? (
          <Spin className="flex center" />
        ) : (
          <>
            {hasExecutionError ? (
              alertErrorExecutionLoad
            ) : (
              <Row>
                <Col span={22}>
                  {pendingFacematch}
                  {executionData?.dynamicCards?.liveness && <Liveness />}
                  {executionData?.dynamicCards?.livenessPending && <LivenessPending />}
                  {executionData?.dynamicCards?.officialDataReprove && (
                    <OfficialDataReprove />
                  )}
                  {executionData?.dynamicCards?.sanctionsReprove && (
                    <SanctionsReprove>{imageTabs(320)}</SanctionsReprove>
                  )}
                  {executionData?.dynamicCards?.customDataAvailable && (
                    <CustomDataAvailable />
                  )}
                  {executionData?.executions?.reviews?.FACEMATCH &&
                    !executionData?.executions?.reviews?.FACEMATCH?.reviewAt && (
                      <SentImageFaceMatch />
                    )}
                  {executionData?.dynamicCards?.deepfake && <DeepfakeConfirmation />}
                  {executionData?.dynamicCards?.confirmDocuments && (
                    <DocumentsConfirmation />
                  )}
                  {executionData?.dynamicCards?.suspectFromSharedFaceset && (
                    <FacesetConfirmation />
                  )}
                  {executionData?.dynamicCards?.suspectFromPrivateFaceset && (
                    <FacesetConfirmation isPrivate />
                  )}
                  {/* Card custom para quando há necessidade de edição dos campos */}
                  {showOCRConfirm && (
                    <OCRDetails
                      imageTabs={imageTabs(320)}
                      complianceRules={<ComplianceRules ocrRules />}
                      parameters={<Parameters ocrParameters />}
                    />
                  )}
                  {executionData?.dynamicCards?.confirmData && (
                    <DataConfirmation>{imageTabs(320)}</DataConfirmation>
                  )}
                  {executionData?.dynamicCards?.confirmLicense && <LicenseConfirmation />}
                  {executionData?.dynamicCards?.rgIssueLessThan25 && (
                    <RGIssueLessThan25>{imageTabs(320)}</RGIssueLessThan25>
                  )}
                  {/* Card custom para quando não há necessidade de edição dos campos */}
                  {showOCRDetails && !showOCRConfirm && (
                    <OCRDetails
                      imageTabs={imageTabs(320)}
                      complianceRules={<ComplianceRules ocrRules />}
                      parameters={<Parameters ocrParameters />}
                    />
                  )}
                  {pendingProcess && <ProcessesInformation />}
                  {((containsImages &&
                    executionData?.executions?.report?.inputType !== 'cpf' &&
                    executionData?.executions?.report?.inputType !== 'cnpj') ||
                    (executionData?.executions?.images &&
                      !_.isEmpty(executionData?.executions?.images))) && <SentImages />}
                  {!showOCRDetails && (
                    <Row>
                      <Col span={8}>
                        <Parameters />
                      </Col>
                    </Row>
                  )}
                  {executionData?.executions?.workflowId && (
                    <WorkflowDetails transactionData={executionData?.executions} />
                  )}
                  {executionData?.executions?.workflowId && (
                    <ServicesDetails transactionData={executionData?.executions} />
                  )}
                  <ProcessedInfos />
                  <ComplianceRules />
                  {!executionData?.dynamicCards?.suspectFromSharedFaceset &&
                    showSharedFacesetReview && <FacesetConfirmation review />}
                  {!executionData?.dynamicCards?.suspectFromPrivateFaceset &&
                    showPrivateFacesetReview && <FacesetConfirmation isPrivate review />}
                </Col>

                <Col span={2}>
                  <DynamicCardsList
                    containsImages={containsImages}
                    containsPendingProcess={pendingProcess}
                    dynamicCards={executionData?.dynamicCards}
                    isWorkflowBuilder={executionData?.executions?.workflowId}
                    showOCRDetails={showOCRDetails}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
        {!hasExecutionError && (
          <>
            <Timeline closeDrawer={closeDrawer} isDrawerVisible={isDrawerOpen} />
            <div className="timeline-button-wrapper">
              <Button
                disabled={loadingExecution}
                onClick={openDrawer}
                className="timeline-button"
              >
                Linha do tempo <Plus1 width={20} height={20} />
              </Button>
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Details;
