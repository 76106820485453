import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCustomer } from 'hooks/customer';
import {
  Col,
  Form,
  Select,
  Switch,
  Divider,
  Row,
  Input,
  Typography,
  List,
  Tag,
  Checkbox,
  message,
} from 'antd';
import Button from 'components/Button';
import SimpleTitleDivider from 'components/Divider/SimpleTitleDivider';
import { ArrowDown, CircleFalse, CheckCircle2 } from '@combateafraude/icons/general';
import { useFetch } from 'services/hooks';
import { CloseCircleFilled } from '@ant-design/icons';

import stateOptions from '../utils/stateOptions.json';

const { Option } = Select;

const { Text } = Typography;

const CheckboxGroup = Checkbox.Group;

const documentOptions = [
  { value: 'RG', label: 'RG' },
  { value: 'CNH', label: 'CNH' },
];

const digitalManipulationDocumentOptions = [
  { value: 'RG', label: 'RG' },
  { value: 'CNH', label: 'CNH' },
  { value: 'OTHERS', label: 'Outros' },
];

const documentOptionsInitialize = ['RG', 'CNH'];
const digitalManipulationDocumentOptionsInitialize = ['RG', 'CNH', 'OTHERS'];

const EditAdditionalSettings = ({ isEditing, form, loading }) => {
  const { customer } = useCustomer();

  const { data: users, get: getUsers, loading: loadingUsers } = useFetch();
  const { get: getQueryTemplates, loading: loadingQueryTemplates } = useFetch();

  const [allQueryTemplates, setAllQueryTemplates] = useState([]);
  const [queryTemplatesSearched, setQueryTemplatesSearched] = useState([]);
  const [searchQueryTemplate, setSearchQueryTemplate] = useState(null);

  const customerDocumentOptions = useMemo(() => {
    const documents = [];
    customer?.wecheckDocumentscopyAutomation?.documents?.forEach((document) => {
      documents.push(document.documentType);
    });

    return documents || [];
  }, [customer]);

  const digitalManipulationCustomerDocumentOptions = useMemo(() => {
    const documents = [];
    customer?.digitalManipulationCheck?.documents?.forEach((document) => {
      documents.push(document.documentType);
    });

    return documents || [];
  }, [customer]);

  const customerStatesOptions = useMemo(() => {
    const states = [];
    customer?.wecheckDocumentscopyAutomation?.documents?.forEach((document) => {
      if (document.documentType === 'RG') states.push(...document.states);
    });

    return states || [];
  }, [customer]);

  const customerDigitalManipulationStatesOptions = useMemo(() => {
    const states = [];
    customer?.digitalManipulationCheck?.documents?.forEach((document) => {
      if (document.documentType === 'RG') states.push(...document.states);
    });

    return states || [];
  }, [customer]);

  const fullStatesOptions = useMemo(() => {
    const states = [];
    stateOptions?.forEach((state) => {
      states.push(state.value);
    });

    return states || [];
  }, []);

  const [selectedDocumentOption, setSelectedDocumentOption] = useState(
    customerDocumentOptions
  );

  const [
    digitalManipulationSelectedDocumentOption,
    setDigitalManipulationSelectedDocumentOption,
  ] = useState(digitalManipulationCustomerDocumentOptions);

  const [statesCheckedList, setStatesCheckedList] = useState(customerStatesOptions);

  const [digitalManipulationStatesCheckedList, setDigitalManipulationStatesCheckedList] =
    useState(customerDigitalManipulationStatesOptions);

  const [automationEnabled, setAutomationEnabled] = useState(
    customer?.wecheckDocumentscopyAutomation?.automation ||
      customer?.wecheckDocumentscopyAutomation === 'caf'
  );

  const [digitalManipulationCheckEnabled, setDigitalManipulationCheckEnabled] = useState(
    customer?.digitalManipulationCheck?.enabled || false
  );

  const setAutomationInitialValues = (value = false) => {
    if (value) {
      setSelectedDocumentOption(documentOptionsInitialize);
      setStatesCheckedList(fullStatesOptions);
    }

    setAutomationEnabled(value);
  };

  const setDigitalManipulationCheckValues = (value = false) => {
    if (value) {
      setDigitalManipulationSelectedDocumentOption(
        digitalManipulationDocumentOptionsInitialize
      );
      setDigitalManipulationStatesCheckedList(fullStatesOptions);
    }

    setDigitalManipulationCheckEnabled(value);
  };

  const [evaluationRulesEnabled, setEvaluationRulesEnabled] = useState(
    customer?.evaluationRulesEnabled
  );
  const [approveEvaluationInput, setApproveEvaluationInput] = useState('');
  const [approveEvaluationRules, setApproveEvaluationRules] = useState(
    customer?.approveEvaluationRules || []
  );

  const [reproveEvaluationInput, setReproveEvaluationInput] = useState('');
  const [reproveEvaluationRules, setReproveEvaluationRules] = useState(
    customer?.reproveEvaluationRules || []
  );

  const [queryTemplatesFilter, setQueryTemplatesFilter] = useState(
    customer?.wecheckDocumentscopyAutomation?.templates || []
  );

  const firstLoad = useRef(true);

  const highlightClass = isEditing ? 'editing-highlight' : '';

  const handleSwitchChange = (checked, fieldName) => {
    const relevantFields = ['wecheckPOC', 'wecheckExclusiveQueue', 'wecheckPremiumQueue']; // Lista de switches relevantes
    const allValues = form.getFieldsValue(); // Obtém todos os valores do formulário

    // Filtra os campos relevantes e verifica quais estão ativados
    const activeFields = relevantFields.filter(
      (key) => key !== fieldName && allValues[key]
    );

    if (activeFields.length > 0 && checked) {
      message.error('Apenas uma fila do Wecheck pode estar ativa!');
      form.setFieldsValue({ [fieldName]: false }); // Desmarca o switch atual
    }
  };

  const addRule = useCallback(
    (type) => {
      if (loading) return;

      if (type === 'approve') {
        setApproveEvaluationRules((prev) => [...prev, approveEvaluationInput]);
        setApproveEvaluationInput('');
        return;
      }
      setReproveEvaluationRules((prev) => [...prev, reproveEvaluationInput]);
      setReproveEvaluationInput('');
    },
    [approveEvaluationInput, reproveEvaluationInput, loading]
  );

  const onCloseTag = useCallback(
    (type, index) => {
      if (loading) return;

      if (type === 'approve') {
        setApproveEvaluationRules((prev) => prev.filter((_, i) => i !== index));
        return;
      }
      setReproveEvaluationRules((prev) => prev.filter((_, i) => i !== index));
    },
    [loading]
  );

  const handleDocumentOptionsChange = (checkedValues) => {
    setSelectedDocumentOption(checkedValues);
  };

  const handleDigitalManipulationDocumentOptionsChange = (checkedValues) => {
    setDigitalManipulationSelectedDocumentOption(checkedValues);
  };

  const checkedAll = stateOptions.length === statesCheckedList.length;
  const checkedAllDigitalManipulation =
    stateOptions.length === digitalManipulationStatesCheckedList.length;

  const onCheckAllChange = () => {
    const documents = form.getFieldValue(['wecheckDocumentscopyAutomation', 'documents']);

    const updatedDocuments = documents.map((document) => {
      return checkedAll
        ? { ...document, states: [] }
        : { ...document, states: fullStatesOptions };
    });

    form.setFieldsValue({
      wecheckDocumentscopyAutomation: {
        automation: true,
        documents: updatedDocuments,
        templates: [],
      },
    });
    setStatesCheckedList(
      stateOptions.length !== statesCheckedList.length ? fullStatesOptions : []
    );
  };

  const onCheckAllDigitalManipulationChange = () => {
    const documents = form.getFieldValue(['digitalManipulationCheck', 'documents']);

    const updatedDocuments = documents.map((document) => {
      return checkedAllDigitalManipulation
        ? { ...document, states: [] }
        : { ...document, states: fullStatesOptions };
    });

    form.setFieldsValue({
      digitalManipulationCheck: {
        enabled: true,
        documents: updatedDocuments,
      },
    });
    setDigitalManipulationStatesCheckedList(
      stateOptions.length !== digitalManipulationStatesCheckedList.length
        ? fullStatesOptions
        : []
    );
  };

  const onChange = (list) => {
    setStatesCheckedList(list);
  };

  const onChangeDigitalManipulation = (list) => {
    setDigitalManipulationStatesCheckedList(list);
  };

  const onQueryTemplateSearch = useCallback(
    (searchTerm) => {
      if (searchTerm.trim() === '') {
        setQueryTemplatesSearched(allQueryTemplates);
        return;
      }

      const queryTemplatesFiltered = allQueryTemplates?.filter((queryTemplate) => {
        if (queryTemplate?.name?.toUpperCase().includes(searchTerm?.toUpperCase())) {
          return queryTemplate;
        }
        return false;
      });
      setSearchQueryTemplate(searchTerm);
      setQueryTemplatesSearched(queryTemplatesFiltered || []);
    },
    [allQueryTemplates]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!customer || !firstLoad.current) return;

    firstLoad.current = false;
    getUsers({
      url: '/users',
    });

    setAllQueryTemplates([]);
    setQueryTemplatesSearched([]);

    if (!customer?.product?.includes('trust')) {
      return;
    }

    const data = await getQueryTemplates({
      url: `/users/${customer.tenantId}/profile-templates`,
      config: {
        params: {
          _sort: 'name',
          _order: 1,
        },
      },
    });

    const queryTemplatesFiltered = data?.docs?.map((queryTemplate) => {
      return { name: queryTemplate?.name, id: queryTemplate?._id };
    });

    setAllQueryTemplates(queryTemplatesFiltered);
    setQueryTemplatesSearched(queryTemplatesFiltered);
  }, [customer, getUsers, getQueryTemplates]);

  useEffect(() => {
    form.setFieldsValue({
      wecheckDocumentscopyAutomation: {
        automation: automationEnabled,
        documents: [{ documentType: selectedDocumentOption, states: statesCheckedList }],
        templates: queryTemplatesFilter || [],
      },
    });

    form.setFieldsValue({
      digitalManipulationCheck: {
        enabled: digitalManipulationCheckEnabled,
        documents: [
          {
            documentType: digitalManipulationSelectedDocumentOption,
            states: digitalManipulationStatesCheckedList,
          },
        ],
      },
    });

    form.setFieldsValue({
      approveEvaluationRules,
      reproveEvaluationRules,
    });
  }, [
    evaluationRulesEnabled,
    form,
    approveEvaluationRules,
    reproveEvaluationRules,
    customerDocumentOptions,
    fullStatesOptions,
    customer,
    customerStatesOptions,
    automationEnabled,
    queryTemplatesFilter,
    selectedDocumentOption,
    statesCheckedList,
    digitalManipulationSelectedDocumentOption,
    digitalManipulationStatesCheckedList,
    digitalManipulationCheckEnabled,
  ]);

  return (
    <Row className="fd-row">
      <Col span={24}>
        <Form.Item
          name={['wecheckDocumentscopyAutomation', 'automation']}
          label="Habilitar documentoscopia híbrida"
          className="title-with-switch"
        >
          <Switch
            disabled={!isEditing || loading}
            checkedChildren="Sim"
            unCheckedChildren="Não"
            checked={automationEnabled}
            onChange={() => setAutomationInitialValues(!automationEnabled)}
          />
        </Form.Item>
        {isEditing && automationEnabled && (
          <Text>
            Todas as informações já estão selecionadas por padrão, porém, você pode
            desmarcar as que não fará uso.
          </Text>
        )}
      </Col>
      {isEditing && automationEnabled && (
        <Col span={24}>
          <SimpleTitleDivider title="Tipo de documento" customBottom={0} />
          <Form.List name={['wecheckDocumentscopyAutomation', 'documents']}>
            {(fields) => (
              <>
                {fields.map(({ name, fieldKey, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      name={[name, 'documentType']}
                      fieldKey={[fieldKey, 'documentType']}
                    >
                      <CheckboxGroup
                        options={documentOptions}
                        initialValues={selectedDocumentOption}
                        value={selectedDocumentOption}
                        onChange={handleDocumentOptionsChange}
                      />
                    </Form.Item>
                    <SimpleTitleDivider title="Estado" customBottom={0} />
                    <Form.Item
                      {...restField}
                      name={[name, 'states']}
                      fieldKey={[fieldKey, 'states']}
                    >
                      <CheckboxGroup
                        options={stateOptions.map((option) => ({
                          ...option,
                          disabled: !selectedDocumentOption.includes('RG'),
                        }))}
                        value={statesCheckedList}
                        onChange={onChange}
                        className="checkboxgroup-column-grid"
                      />
                    </Form.Item>
                    <div className="checkall-button">
                      <Button
                        className="btn-custom-secondary"
                        onClick={() => onCheckAllChange()}
                        disabled={!isEditing || loading}
                      >
                        {checkedAll ? 'Desmarcar todos' : 'Marcar todos'}
                      </Button>
                    </div>
                  </>
                ))}
              </>
            )}
          </Form.List>

          <SimpleTitleDivider title="Modelos de Consulta" customBottom={0} />

          <Form.Item
            name={['wecheckDocumentscopyAutomation', 'templates']}
            label="Lista de consultas selecionadas a passar pela automação. Caso não seja selecionado nenhum modelo em específico, de modo que a lista esteja vazia, isso significa que todos passarão por esta análise."
          >
            <Select
              mode="multiple"
              optionFilterProp="label"
              disabled={loadingQueryTemplates}
              defaultActiveFirstOption={false}
              style={{ width: 265 }}
              value={queryTemplatesFilter || []}
              allowClear
              placeholder="Busque por modelo de consulta..."
              dropdownClassName="multiselect-filters"
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<CheckCircle2 width={20} height={20} />}
              loading={loadingQueryTemplates}
              notFoundContent={
                !loadingQueryTemplates ? (
                  <span>
                    {searchQueryTemplate
                      ? `Nenhum resultado encontrado para "${searchQueryTemplate}"`
                      : 'Nenhum template encontrado para esse cliente!'}
                  </span>
                ) : (
                  <span>Selecione um cliente para visualizar os modelos!</span>
                )
              }
              onSearch={onQueryTemplateSearch}
              onBlur={() => {
                setSearchQueryTemplate(null);
                setQueryTemplatesSearched(allQueryTemplates);
              }}
              onChange={(values) => {
                setQueryTemplatesFilter(values);
              }}
            >
              {queryTemplatesSearched?.map((queryTemplate) => (
                <Option key={queryTemplate.id} label={queryTemplate.name}>
                  {queryTemplate.name.length > 30
                    ? queryTemplate.name.substr(0, 27).concat('...')
                    : queryTemplate.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Divider />

      <Col span={24}>
        <Form.Item
          name={['digitalManipulationCheck', 'enabled']}
          label="Habilitar provedor de análise de manipulação digital na documentoscopia"
          className="title-with-switch"
        >
          <Switch
            disabled={!isEditing || loading}
            checkedChildren="Sim"
            unCheckedChildren="Não"
            checked={digitalManipulationCheckEnabled}
            onChange={() =>
              setDigitalManipulationCheckValues(!digitalManipulationCheckEnabled)
            }
          />
        </Form.Item>
        {isEditing && digitalManipulationCheckEnabled && (
          <Text>
            Todas as informações já estão selecionadas por padrão, porém, você pode
            desmarcar as que não fará uso.
          </Text>
        )}
      </Col>
      {isEditing && digitalManipulationCheckEnabled && (
        <Col span={24}>
          <SimpleTitleDivider title="Tipo de documento" customBottom={0} />
          <Form.List name={['digitalManipulationCheck', 'documents']}>
            {(fields) => (
              <>
                {fields.map(({ name, fieldKey, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      name={[name, 'documentType']}
                      fieldKey={[fieldKey, 'documentType']}
                    >
                      <CheckboxGroup
                        options={digitalManipulationDocumentOptions}
                        initialValues={digitalManipulationSelectedDocumentOption}
                        value={digitalManipulationSelectedDocumentOption}
                        onChange={handleDigitalManipulationDocumentOptionsChange}
                      />
                    </Form.Item>
                    <SimpleTitleDivider title="Estado" customBottom={0} />
                    <Form.Item
                      {...restField}
                      name={[name, 'states']}
                      fieldKey={[fieldKey, 'states']}
                    >
                      <CheckboxGroup
                        options={stateOptions.map((option) => ({
                          ...option,
                          disabled:
                            !digitalManipulationSelectedDocumentOption.includes('RG'),
                        }))}
                        value={digitalManipulationStatesCheckedList}
                        onChange={onChangeDigitalManipulation}
                        className="checkboxgroup-column-grid"
                      />
                    </Form.Item>
                    <div className="checkall-button">
                      <Button
                        className="btn-custom-secondary"
                        onClick={() => onCheckAllDigitalManipulationChange()}
                        disabled={!isEditing || loading}
                      >
                        {checkedAllDigitalManipulation
                          ? 'Desmarcar todos'
                          : 'Marcar todos'}
                      </Button>
                    </div>
                  </>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item
            name={['digitalManipulationCheck', 'disableRulesDecision']}
            valuePropName="checked"
            label="Desabilitar conjunto de regras na tomada de decisão"
          >
            <Switch
              disabled={!isEditing || loading}
              checkedChildren="Sim"
              unCheckedChildren="Não"
            />
          </Form.Item>
        </Col>
      )}
      <Divider />

      <Col span={12}>
        <Form.Item
          name={['wecheckFlexibleEvaluation']}
          valuePropName="checked"
          label="Avaliação flexível no WeCheck"
        >
          <Switch
            disabled={!isEditing || loading}
            checkedChildren="Sim"
            unCheckedChildren="Não"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={['wecheckStrictEvaluation']}
          valuePropName="checked"
          label="Avaliação rígida no WeCheck"
        >
          <Switch
            disabled={!isEditing || loading}
            checkedChildren="Sim"
            unCheckedChildren="Não"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={['wecheckPremiumQueue']}
          valuePropName="checked"
          label="Fila premium no WeCheck"
        >
          <Switch
            disabled={!isEditing || loading}
            onChange={(checked) => handleSwitchChange(checked, 'wecheckPremiumQueue')}
            checkedChildren="Sim"
            unCheckedChildren="Não"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={['wecheckPOC']}
          valuePropName="checked"
          label="Fila POC no WeCheck"
        >
          <Switch
            disabled={!isEditing || loading}
            onChange={(checked) => handleSwitchChange(checked, 'wecheckPOC')}
            checkedChildren="Sim"
            unCheckedChildren="Não"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={['wecheckExclusiveQueue']}
          valuePropName="checked"
          label="Fila exclusiva no WeCheck"
        >
          <Switch
            disabled={!isEditing || loading}
            onChange={(checked) => handleSwitchChange(checked, 'wecheckExclusiveQueue')}
            checkedChildren="Sim"
            unCheckedChildren="Não"
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="status" className="no-mrg-btm" label="Status">
          <Select
            disabled={!isEditing || loading}
            className={`text-dark ${highlightClass}`}
            suffixIcon={<ArrowDown />}
            options={[
              { value: 'lead', label: 'Lead' },
              { value: 'active', label: 'Ativo' },
              { value: 'poc', label: 'Teste' },
              { value: 'internal-customer', label: 'Cliente Interno' },
              { value: 'integration', label: 'Integração' },
              { value: 'inactive', label: 'Inativo' },
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="salesperson" className="no-mrg-btm" label="Responsável">
          <Select
            disabled={!isEditing || loading}
            className={highlightClass}
            showSearch
            loading={loadingUsers}
            placeholder="Selecione o responsável"
            defaultActiveFirstOption={false}
            optionFilterProp="label"
            suffixIcon={<ArrowDown />}
          >
            {users?.docs?.map((d) => (
              <Option
                disabled={d.deletedAt}
                key={d.username}
                label={d.name}
                value={d.username}
              >
                {d.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="language"
          className="no-mrg-btm"
          label="Idioma"
          rules={[{ required: true }]}
        >
          <Select
            disabled={!isEditing || loading}
            className={highlightClass}
            placeholder="Selecione o idioma"
            suffixIcon={<ArrowDown />}
            options={[
              { value: 'en-US', label: 'Inglês' },
              { value: 'es-MX', label: 'Espanhol' },
              { value: 'pt-BR', label: 'Português' },
            ]}
          />
        </Form.Item>
      </Col>
      <Divider />
      <Col span={24}>
        <Form.Item
          name="evaluationRulesEnabled"
          label="Regras específicas de avaliação no WeCheck"
          className="title-with-switch"
        >
          <Switch
            disabled={!isEditing || loading}
            checkedChildren="Sim"
            checked={evaluationRulesEnabled}
            onChange={setEvaluationRulesEnabled}
          />
        </Form.Item>
      </Col>
      {isEditing && evaluationRulesEnabled && (
        <Col span={24}>
          <Form.Item label="Motivos para aprovar o documento">
            <div className="flex">
              <Input
                placeholder="Digite uma regra para aprovar o documento..."
                className={`input-with-button ${highlightClass}`}
                value={approveEvaluationInput}
                onChange={(e) => setApproveEvaluationInput(e.target.value)}
              />
              <Button
                className="btn-custom btn-custom-primary"
                onClick={() => addRule('approve')}
                disabled={!isEditing || loading}
              >
                Adicionar
              </Button>
            </div>
          </Form.Item>
        </Col>
      )}
      {evaluationRulesEnabled && (
        <Col span={24}>
          <Form.Item name="approveEvaluationRules">
            <Text>Regras de aprovação já inseridas:</Text>
            <List
              dataSource={approveEvaluationRules}
              split={false}
              renderItem={(item, index) => (
                <List.Item className="list-item">
                  <Tag
                    className="approve-tag"
                    closable={isEditing}
                    closeIcon={<CloseCircleFilled />}
                    onClose={() => onCloseTag('approve', index)}
                  >
                    {item}
                  </Tag>
                </List.Item>
              )}
            />
          </Form.Item>
        </Col>
      )}
      <Divider />
      {isEditing && evaluationRulesEnabled && (
        <Col span={24}>
          <Form.Item label="Motivos para reprovar o documento">
            <div className="flex">
              <Input
                placeholder="Digite uma regra para reprovar o documento..."
                className={`input-with-button ${highlightClass}`}
                value={reproveEvaluationInput}
                onChange={(e) => setReproveEvaluationInput(e.target.value)}
              />
              <Button
                className="btn-custom btn-custom-primary"
                disabled={!isEditing || loading}
                onClick={() => addRule('reprove')}
              >
                Adicionar
              </Button>
            </div>
          </Form.Item>
        </Col>
      )}
      {evaluationRulesEnabled && (
        <Col span={24}>
          <Form.Item name="reproveEvaluationRules">
            <Text>Regras de reprovação já inseridas:</Text>
            <List
              dataSource={reproveEvaluationRules}
              split={false}
              renderItem={(item, index) => (
                <List.Item className="list-item">
                  <Tag
                    className="reprove-tag"
                    closable={isEditing}
                    closeIcon={<CloseCircleFilled />}
                    onClose={() => onCloseTag('reprove', index)}
                  >
                    {item}
                  </Tag>
                </List.Item>
              )}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

EditAdditionalSettings.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  form: PropTypes.shape.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditAdditionalSettings;
